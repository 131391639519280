import VueRouter from 'vue-router'
export default new VueRouter({
    routes:[
      {
        path:'/',
        redirect:'/index',
      },
      {
        path:'/index',
        name:"",
        component:()=>import('../views/index.vue'),
  
      },
    ]
})